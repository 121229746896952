@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/buttons";
@use "../../../../../../../sedestral/sass/extends/inputs";
@use "src/sedestral/sass/mixins/outline";

.componentAccountPasswordLost {
  height: 100%;
  width: 100%;

  .details {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;

    .icon {
      height: 80px;
      width: 80px;
      margin-bottom: 5px;

      @include icons.ico("3d-password");
    }

    .description {
      font-size: 13px;
      text-align: center;
    }
  }

  .form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin: 0;

    .inputContainer {
      height: 42px;
      position: relative;
    }

    .captchaContainer {
      position: relative;
    }


    .button {
      @include buttons.grey;
      @include positioning.flex-globally;
      @include texts.font("bold");

      height: 52px;
      font-size: 14px;
      border-radius: 14px;
      background: var(--blue);
      position: relative;
      width: 100%;
      color: var(--white) !important;

      circle {
        stroke: var(--white) !important;
      }

      &:hover {
        background: var(--blue-hover);
      }

      @include positioning.flex-globally;
      @include texts.font("semi-bold");

      circle {
        stroke: var(--white);
        stroke-width: 4px;
      }
    }
  }

  .menu {
    font-size: 11px;
    margin-top: 15px;
    width: 100%;

    @include positioning.clear;

    .text {
      @include buttons.round;
      @include texts.ellipsis(1);
      @include texts.font("semi-bold");

      padding: 8px 10px;
      border-radius: 20px;
      width: fit-content;
      cursor: pointer;

      &.left {
        text-align: left;
        float: left;
        margin-left: -5px;
      }
    }

    .login {
      visibility: visible;
    }
  }
}